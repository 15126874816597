import { useMemo } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, ErrorComponent } from "@tanstack/react-router";

import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { LoadingComponent } from "src/components/LoadingComponent";
import { LocationImportNotifications } from "src/components/LocationImportNotification";
import { useRevisionLocations } from "src/hooks/useRevisionLocations";
import { RevisionOverviewInfo } from "src/pages/revisionOverview/RevisionOverviewInfo";
import { RevisionOverviewSteps } from "src/pages/revisionOverview/RevisionOverviewSteps";
import {
  getRevisionHorizonQuery,
  getRevisionLocationGroupQuery,
  getRevisionQuery,
  getRevisionSoilUnitQuery,
} from "src/queries/queries";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/",
)({
  component: ProjectPhaseRevisionPage,
});

function ProjectPhaseRevisionPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();

  const {
    isPending: isRevisionPending,
    isError: isRevisionError,
    data: revision,
  } = useQuery(getRevisionQuery(project_id, phase_id, revision_id));

  const {
    hasLocationCPTMissing,
    isLocationsPending,
    isLocationsError,
    locations,
  } = useRevisionLocations({ project_id, phase_id, revision_id });

  const {
    isPending: isLocationGroupPending,
    isError: isLocationGroupsError,
    data: locationGroups,
  } = useQuery(getRevisionLocationGroupQuery(revision_id));

  const {
    isPending: isSoilUnitsPending,
    isError: isSoilUnitsError,
    data: soilUnits,
  } = useQuery(getRevisionSoilUnitQuery(revision_id));

  const {
    isPending: isHorizonsPending,
    isError: isHorizonsError,
    data: horizons,
  } = useQuery(getRevisionHorizonQuery(revision_id));

  const isPending = useMemo(
    () =>
      isRevisionPending ||
      isLocationsPending ||
      isLocationGroupPending ||
      isSoilUnitsPending ||
      isHorizonsPending,
    [
      isHorizonsPending,
      isLocationGroupPending,
      isLocationsPending,
      isRevisionPending,
      isSoilUnitsPending,
    ],
  );

  const isError = useMemo(
    () =>
      isRevisionError ||
      isLocationsError ||
      isLocationGroupsError ||
      isSoilUnitsError ||
      isHorizonsError,
    [
      isHorizonsError,
      isLocationGroupsError,
      isLocationsError,
      isRevisionError,
      isSoilUnitsError,
    ],
  );

  // if (isRevisionPending) {
  //   return <LoadingComponent />;
  // }

  if (isError) {
    return <ErrorComponent error={isError} />;
  }

  return (
    <>
      <ProjectNavigation
        project_id={project_id}
        phase_id={phase_id}
        revision_id={revision_id}
        hasLocationCPTMissing={hasLocationCPTMissing || locations?.length === 0}
      />
      <AppContent>
        <Box className="flex flex-col gap-4 max-w-wider mx-auto">
          <RevisionOverviewInfo
            revision={revision}
            isRevisionPending={isRevisionPending}
          />
          <RevisionOverviewSteps
            project_id={project_id}
            phase_id={phase_id}
            revision_id={revision_id}
            locations={locations || []}
            locationGroups={locationGroups}
            soilUnits={soilUnits || []}
            horizons={horizons || []}
            isSoilUnitsPending={isSoilUnitsPending}
            isLocationGroupPending={isLocationGroupPending}
            isLocationsPending={isLocationsPending}
            isHorizonsPending={isHorizonsPending}
          />
        </Box>
      </AppContent>
      {hasLocationCPTMissing && (
        <LocationImportNotifications locations={locations} />
      )}
    </>
  );
}
