import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { createColumnHelper, SortingState } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { LocationGroup } from "src/types/locationGroup";
import { LocationNameMapping } from "src/types/locations";
import { sortData, SortOption } from "src/utils/arrays";
import { LocationGroupPlotPreview } from "./LocationGroupPlotPreview";
import { LocationGroupCPTPreviewCellFactory } from "./tableFeatures/LocationGroupCPTPreviewCell";
import { LocationNameCellFactory } from "./tableFeatures/LocationNameCell";

type Props = {
  project_id: string;
  locationNameMapping: LocationNameMapping;
  locationGroups: LocationGroup[];
};

const tableColumnHelper = createColumnHelper<LocationGroup>();

export const LocationGroupTable = ({
  project_id,
  locationNameMapping,
  locationGroups,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedLocationGroup, setSelectedLocationGroup] =
    useState<LocationGroup>(locationGroups[0]);

  const handleSelectLocationGroupPreview = useCallback(
    (location_group_id: string, triggerOpendialog: boolean = false) => {
      const locationGroup = locationGroups.find(
        (group) => group.location_group_id === location_group_id,
      );
      if (locationGroup) {
        setSelectedLocationGroup(locationGroup);
        if (triggerOpendialog) {
          setOpen(true);
        }
      }
    },
    [locationGroups],
  );

  const [sorting, setSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);

  // Memoize sorted location groups based on current sorting state
  const sortedLocationGroups = useMemo(
    () =>
      sortData<LocationGroup>(
        locationGroups,
        sorting as SortOption<LocationGroup>[],
      ),
    [locationGroups, sorting],
  );

  const columns = useMemo(
    () => [
      tableColumnHelper.accessor("name", {
        cell: (row) => row.getValue(),
        header: "Name",
        size: 100,
        sortUndefined: "last",
        sortDescFirst: false,
      }),
      tableColumnHelper.accessor("description", {
        cell: (row) => row.getValue(),
        header: "description",
        size: 200,
        sortUndefined: "last",
        sortDescFirst: false,
      }),
      tableColumnHelper.accessor("location_ids", {
        cell: LocationNameCellFactory(locationNameMapping),
        header: "Locations",
        size: 400,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false, // TODO: create filter to filter locations
      }),
      tableColumnHelper.accessor("max_depth", {
        cell: (row) => row.getValue(),
        header: "Max depth [m]",
        size: 120,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false, // TODO: create filter to filter locations
      }),
      tableColumnHelper.accessor("end_of_borehole", {
        cell: (row) => row.getValue(),
        header: "EOB [m]",
        size: 80,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false, // TODO: create filter to filter locations
      }),
      tableColumnHelper.accessor("location_group_id", {
        cell: LocationGroupCPTPreviewCellFactory(
          handleSelectLocationGroupPreview,
        ),
        header: "",
        size: 50,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false,
        id: "Actions",
      }),
    ],
    [handleSelectLocationGroupPreview, locationNameMapping],
  );

  return (
    <Box>
      <Datatable
        data={locationGroups}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        usePagination={locationGroups.length > 10}
        pinnedColumns={{ right: ["Actions"] }}
      />
      {open && (
        <LocationGroupPlotPreview
          project_id={project_id}
          locationGroup={selectedLocationGroup}
          locationGroups={sortedLocationGroups}
          locationNameMapping={locationNameMapping}
          open={open}
          setOpen={setOpen}
          setLocationGroup={handleSelectLocationGroupPreview}
        />
      )}
    </Box>
  );
};
