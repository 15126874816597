import React, { useMemo } from "react";
import {
  Alert,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { PlotSelection } from "src/components/PlotSelection";
import { GeoPlot } from "src/types/plots";

type PlotSettingsProps = {
  numPlotPerRow: number;
  setNumPlotPerRow: React.Dispatch<React.SetStateAction<number>>;
  showDepthRange: boolean;
  yMin?: number;
  setYMin?: React.Dispatch<React.SetStateAction<number>>;
  yMax?: number;
  setYMax?: React.Dispatch<React.SetStateAction<number>>;
  defaultMaxDepth?: number;
  selectedPlots: GeoPlot[];
  setSelectedPlots: React.Dispatch<React.SetStateAction<GeoPlot[]>>;
  plots: GeoPlot[];
};

const plotPerRowOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const PlotSettings: React.FC<PlotSettingsProps> = ({
  numPlotPerRow,
  setNumPlotPerRow,
  selectedPlots,
  setSelectedPlots,
  showDepthRange,
  yMin,
  setYMin,
  yMax,
  setYMax,
  defaultMaxDepth,
  plots,
}) => {
  const handlePlotPerRowChange = (e: SelectChangeEvent) => {
    setNumPlotPerRow(parseInt(e.target.value));
  };

  const shouldShowDepthRange = useMemo(
    () =>
      showDepthRange &&
      yMin !== undefined &&
      yMax !== undefined &&
      setYMin !== undefined &&
      setYMax !== undefined &&
      defaultMaxDepth !== undefined,
    [defaultMaxDepth, setYMax, setYMin, showDepthRange, yMax, yMin],
  );

  return (
    <div className="h-full">
      <div className="grid grid-cols-1 gap-8">
        {shouldShowDepthRange && (
          <div className="flex flex-col items-center justify-center min-w-64">
            <div className="w-full pb-2">
              <Typography variant="h6">Depth range for the plots</Typography>
            </div>
            <Alert severity="info">
              Note: this is temporary way to set the depth until plot zoom and
              axis handling are implemented.
            </Alert>
            <FormControl className="!flex !flex-row space-x-4 w-full !pt-4">
              <div>
                <TextField
                  value={yMin}
                  onChange={(e) => (setYMin ? setYMin(+e.target.value) : null)}
                  label="depth min"
                  type="number"
                />
              </div>
              <div>
                <TextField
                  value={yMax}
                  onChange={(e) => (setYMax ? setYMax(+e.target.value) : null)}
                  label="depth max"
                  type="number"
                />
              </div>
              <Button
                onClick={() =>
                  setYMax && defaultMaxDepth ? setYMax(defaultMaxDepth) : null
                }
              >
                set to default max depth
              </Button>
            </FormControl>
          </div>
        )}
        <div className="flex items-center justify-start min-w-64 gap-8">
          <div className="flex flex-col w-64">
            <Typography variant="h6">Plots per row</Typography>
            <Typography variant="caption">
              This only applies to large screens
            </Typography>
          </div>
          <FormControl className="w-48">
            <Select
              value={`${numPlotPerRow}`}
              onChange={handlePlotPerRowChange}
            >
              {plotPerRowOptions.map((num, i) => (
                <MenuItem key={i} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-col">
          <div className="w-64 pb-2">
            <Typography variant="h6">Select plots:</Typography>
          </div>
          <div>
            <PlotSelection
              selectedPlots={selectedPlots}
              setSelectedPlots={setSelectedPlots}
              plots={plots}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
