import { TablePagination } from "@mui/material";
import { Table as ReactTable } from "@tanstack/react-table";

import TablePaginationActions from "./features/TablePaginationActions";

type DatatablePaginationProps<T> = {
  table: ReactTable<T>;
  dataLength: number;
};

export const DatatablePagination = <T,>({
  table,
  dataLength,
}: DatatablePaginationProps<T>) => {
  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, { label: "All", value: dataLength }]}
      component="div"
      count={table.getFilteredRowModel().rows.length}
      rowsPerPage={pageSize}
      page={pageIndex}
      slotProps={{
        select: {
          inputProps: { "aria-label": "rows per page" },
          native: true,
        },
      }}
      onPageChange={(_, page) => {
        table.setPageIndex(page);
      }}
      onRowsPerPageChange={(e) => {
        const size = e.target.value ? Number(e.target.value) : 10;
        table.setPageSize(size);
      }}
      ActionsComponent={TablePaginationActions}
    />
  );
};
