import { useCallback, useEffect, useRef } from "react";
import { Column, Header } from "@tanstack/react-table";

import { DEFAULT_COLUMN_SIZE } from "./DatatableConfig";

export function defaultSetData<T>(updateFn: (layers: T[]) => T[]) {
  console.log("updateData function triggered");
  console.log(updateFn);
}

export function debugUpdateData(
  rowIndex: number,
  columnId: string,
  value: unknown,
) {
  console.log("updateData: rowIndex", rowIndex);
  console.log("updateData: columnId", columnId);
  console.log("updateData: value", value);
}

// see: https://tanstack.com/table/latest/docs/framework/react/examples/editable-data
export function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

const setSize = (size: number | undefined): number | undefined =>
  size !== DEFAULT_COLUMN_SIZE.size ? size : undefined;

/**
 * Gets the size of the header if it is not the default size.
 */
export const getHeaderSize = <T>(
  header: Header<T, unknown>,
): number | undefined => {
  const size = header.getSize();
  return setSize(size);
};

/**
 * Gets the size of the column if it is not the default size.
 */
export const getColumnSize = <T>(column: Column<T>): number | undefined => {
  const size = column.getSize();
  return setSize(size);
};

/**
 * Computes common styles for pinned columns to support sticky headers.
 */
// These are the important styles to make sticky column pinning work!
// Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
// View the index.css file for more needed styles such as border-collapse: separate
export const getCommonPinningStyles = <T>(
  column: Column<T>,
): React.CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  const size = column.getSize();

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-4px 0 4px -5px gray inset"
      : isFirstRightPinnedColumn
        ? "4px 0 4px -5px gray inset"
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    width: isPinned ? size : getColumnSize(column),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: "white",
  };
};
