import { Box } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

import { HORIZON_COLOR } from "src/definitions/constants";
import { Horizon } from "src/types/horizon";
import { TableCustomCell } from "src/types/table";
import { rgbaToHex } from "src/utils/color";

export const HorizonColorCell = ({
  getValue,
  row,
  column,
  table,
}: TableCustomCell<Horizon, Horizon["color"]>) => {
  const color = getValue() || HORIZON_COLOR;

  const updateData = table.options.meta?.updateData;

  const handleColorChange = (newColor: string) => {
    const colorAsHex = rgbaToHex(newColor);
    if (updateData) {
      updateData(row.index, column.id, colorAsHex);
    }
  };

  return (
    <Box>
      <MuiColorInput
        value={color}
        onChange={handleColorChange}
        className="rounded-full"
        variant="standard"
        sx={{
          width: 32,
          height: 32,
          borderRadius: "50%",
          padding: 0,
          ".MuiInputBase-input": {
            textAlign: "center",
            opacity: 0,
          },
          // Override the default bottom border using ::before pseudo-element
          "& .MuiInputBase-root::before": {
            borderBottom: "none",
          },
        }}
      />
    </Box>
  );
};
