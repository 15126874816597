import { useCallback, useMemo } from "react";
import {
  Controller,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { matchIsValidColor, MuiColorInput } from "mui-color-input";

import { NButton, NDialog } from "@ngi/react-component";
import {
  addRevisionSoilUnitMutationQuery,
  updateRevisionSoilUnitMutationQuery,
} from "src/queries/mutations";
import {
  createSoilUnitSchema,
  CreateSoilUnitSchemaType,
  SoilTypeSchema,
  soilUnitDefaultValues,
} from "src/schemas/unitSoilLayerSchema";
import { MakeOptional } from "src/utils/types";
import { isCohesiveSoil } from "./soil-unit-utils";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  revision_id: string;
  soil_unit_id?: string;
  initialData?: MakeOptional<CreateSoilUnitSchemaType, "description">;
};

const soilTypeOptions = SoilTypeSchema.options.map((s) => ({
  id: s,
  label: s,
}));

export const CreateOrUpdateSoilUnit = ({
  open,
  setOpen,
  initialData = soilUnitDefaultValues,
  revision_id,
  soil_unit_id,
}: Props) => {
  const formContext = useForm<CreateSoilUnitSchemaType>({
    mode: "onTouched",
    resolver: zodResolver(createSoilUnitSchema),
    defaultValues: {
      ...initialData,
    },
  });

  const isValid = formContext.formState.isValid;
  console.log("isValid", isValid);
  const soil_type = formContext.watch("soil_type");

  const hasChanged = formContext.formState.isDirty;
  console.log("hasChanged", hasChanged);

  const createRevisionSoilUnitMutation = useMutation(
    addRevisionSoilUnitMutationQuery(revision_id),
  );

  const updateRevisionSoilUnitMutation = useMutation(
    updateRevisionSoilUnitMutationQuery(revision_id),
  );

  const text = useMemo(
    () => (soil_unit_id ? "Update soil layer" : "Create new soil layer"),
    [soil_unit_id],
  );

  const handleCreateSoilUnit = useCallback(async () => {
    const isValid = await formContext.trigger();
    if (!isValid) return;

    if (soil_unit_id) {
      const values = formContext.getValues();
      updateRevisionSoilUnitMutation.mutate({
        soil_unit_id,
        payload: { ...values, is_default: values.is_default || false },
      });
    } else {
      createRevisionSoilUnitMutation.mutate({
        revision_id,
        payload: formContext.getValues(),
      });
    }
    setOpen(false);
  }, [
    formContext,
    soil_unit_id,
    setOpen,
    updateRevisionSoilUnitMutation,
    revision_id,
    createRevisionSoilUnitMutation,
  ]);

  const actions = useMemo(() => {
    const disabled = !isValid || !hasChanged;
    return (
      <>
        <NButton onClick={handleCreateSoilUnit} disabled={disabled}>
          {text}
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    );
  }, [handleCreateSoilUnit, hasChanged, isValid, setOpen, text]);

  return (
    <NDialog
      dialogTitle={text}
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
      maxWidth="md"
    >
      <Box>
        <FormContainer formContext={formContext}>
          <Grid container spacing={6} sx={{ px: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="pb-4">
                Descriptions
              </Typography>
              <TextFieldElement
                name="name"
                label="Name"
                required
                sx={{ my: 1, width: "100%" }}
              />
              <SelectElement
                name="soil_type"
                label="Soil type"
                options={soilTypeOptions}
                sx={{ my: 1, width: "100%" }}
                onChange={() => {
                  formContext.trigger("soil_type");
                }}
              />
              <TextFieldElement
                name="description"
                label="Description"
                multiline
                rows={4}
                sx={{ my: 1, width: "100%" }}
              />

              <Box className="w-full py-2">
                <Controller
                  name="color"
                  control={formContext.control}
                  rules={{ validate: matchIsValidColor }}
                  render={({ field, fieldState }) => (
                    <MuiColorInput
                      {...field}
                      label="Color"
                      className="w-full"
                      format="hex"
                      helperText={fieldState.invalid ? "Color is invalid" : ""}
                      error={fieldState.invalid}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="pb-4">
                Parameters
              </Typography>
              <TextFieldElement
                name="parameters.uw"
                label="Unit weight"
                type="number"
                sx={{ my: 1, width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kN/m3</InputAdornment>
                  ),
                }}
              />
              {isCohesiveSoil(soil_type) && (
                <TextFieldElement
                  name="parameters.Nkt"
                  label="Nkt"
                  type="number"
                  sx={{ my: 1, width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">-</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
    </NDialog>
  );
};
