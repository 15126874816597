import { AxisRange, GeoPlot } from "src/types/plots";
import { GEO } from "./geoParameters";

export const SOIL_UNIT_COLOR_OPACITY = 0.15;
export const SOIL_UNIT_COLOR_OPACITY_OVERVIEW = 0.35;
const xAxisRange = { min: "auto", max: "auto" } as AxisRange;

export const RAW_CPT_PLOTS: GeoPlot[] = [
  {
    id: "raw-cpt-plot-1",
    order: 1,
    title: "qc vs depth",
    xAxisTitle: GEO.qc.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.qc], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-2",
    order: 2,
    title: "fs vs depth",
    xAxisTitle: GEO.fs.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.fs], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-3",
    order: 3,
    title: "u2 vs depth",
    xAxisTitle: GEO.u2.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.u2], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-4",
    order: 4,
    title: "temperature vs depth",
    xAxisTitle: GEO.temperature.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.temperature], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-5",
    order: 5,
    title: "tilt vs depth",
    xAxisTitle: GEO.tilt.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.tilt], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-6",
    order: 6,
    title: "Penetration force vs depth",
    xAxisTitle: GEO.penetration_force.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.penetration_force], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "raw-cpt-plot-7",
    order: 7,
    title: "Penetration rate vs depth",
    xAxisTitle: GEO.penetration_rate.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.penetration_rate], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
];

export const PROCESSED_CPT_PLOTS: GeoPlot[] = [
  ...RAW_CPT_PLOTS.map((plot) => ({ ...plot, default: false })),
  {
    id: "processed-cpt-plot-1",
    order: 8,
    title: "uw vs depth",
    xAxisTitle: GEO.uw.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.uw], y: GEO.depth },
    default: false,
    splitDataPerMethodID: false,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-2",
    order: 9,
    title: "Nkt vs depth",
    xAxisTitle: GEO.Nkt.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Nkt], y: GEO.depth },
    default: false,
    splitDataPerMethodID: false,
    showOnlyOneCPT: true,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-3",
    order: 10,
    title: "Vertical stress vs depth",
    xAxisTitle: GEO.sigV.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.sigVtEff, GEO.sigVtTot, GEO.u0], y: GEO.depth },
    default: true,
    splitDataPerMethodID: false,
    showOnlyOneCPT: true,
    xAxisRange: { min: 0, max: "auto" },
  },
  {
    id: "processed-cpt-plot-4",
    order: 11,
    title: "cone vs depth",
    xAxisTitle: GEO.qc.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.qc, GEO.qn, GEO.qt], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-5",
    order: 12,
    title: "Qt vs depth",
    xAxisTitle: GEO.Qt.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Qt], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-6",
    order: 13,
    title: "Qtn vs depth",
    xAxisTitle: GEO.Qtn.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Qtn], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-7",
    order: 14,
    title: "Fr vs depth",
    xAxisTitle: GEO.Fr.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Fr], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-8",
    order: 15,
    title: "Rf vs depth",
    xAxisTitle: GEO.Rf.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Rf], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-9",
    order: 16,
    title: "Bq vs depth",
    xAxisTitle: GEO.Bq.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Bq], y: GEO.depth },
    default: false,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange,
  },
  {
    id: "processed-cpt-plot-10",
    order: 17,
    title: "Icn vs depth",
    xAxisTitle: GEO.Icn.axisLabel,
    yAxisTitle: GEO.depth.axisLabel,
    series: { x: [GEO.Icn], y: GEO.depth },
    default: true,
    splitDataPerMethodID: true,
    showOnlyOneCPT: false,
    xAxisRange: { min: 0, max: 4.5 },
  },
];
