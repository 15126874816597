import { FormattedData } from "src/components/common/plots/elements/editableScatter";
import {
  LAYER_CIRCLE_RADIUS,
  SUBLAYER_CIRCLE_RADIUS,
} from "src/definitions/constants";
import { LayerTypeSchema, SoilLayerType } from "src/schemas/soilLayerSchema";

export const conditionalStyle = (
  currentPoint: FormattedData<SoilLayerType>,
) => {
  if (currentPoint.data.depth === 0) {
    return { radius: 0 };
  }
  if (currentPoint.data.layer_type === LayerTypeSchema.Values.SUBLAYER) {
    return {
      fill: "#888",
      stroke: "black",
      strokeWidth: 1,
      radius: SUBLAYER_CIRCLE_RADIUS,
    };
  }
  return {
    fill: "black",
    stroke: "none",
    strokeWidth: 0,
    radius: LAYER_CIRCLE_RADIUS,
  };
};
