import {
  LayerTypeSchema,
  soilLayerSchema,
  SoilLayerType,
} from "src/schemas/soilLayerSchema";
import { LocationGroup } from "src/types/locationGroup";

export type InputData = Record<string, string | number>;

export type Entries = {
  colIndex: number;
  unit: string;
  key: string;
  col: string;
  value: string | number;
};

type LayerOutputData = { unit: string; entries: Entries[] };

export type LayerOutput = {
  excelName: string;
  excelLocationPurpose: string;
  data: LayerOutputData[];
};

export function groupByColIndexAndUnit(data: InputData): LayerOutput {
  const excelNameKey = Object.keys(data).find((key) =>
    key.toLowerCase().includes("location_id_group"),
  );
  const excelName = excelNameKey ? (data[excelNameKey] as string) : "";

  const excelLocationPurposeKey = Object.keys(data).find((key) =>
    key.toLowerCase().includes("location_purpose_combined"),
  );
  const excelLocationPurpose = excelLocationPurposeKey
    ? (data[excelLocationPurposeKey] as string)
    : "";

  const parsedData = Object.entries(data)
    .map(([key, value]) => {
      const [colIndexStr, unit, keyPart, col] = key.split("|");
      const colIndex =
        colIndexStr === "undefined" ? undefined : parseInt(colIndexStr, 10);
      return { colIndex, unit, key: keyPart, col, value };
    })
    .filter((entry) => entry.colIndex !== undefined) as Entries[];

  parsedData.sort((a, b) => a.colIndex - b.colIndex);

  const groupedData: LayerOutputData[] = [];

  let currentGroup: LayerOutputData | null = null;

  for (const entry of parsedData) {
    if (
      !currentGroup ||
      entry.unit !== currentGroup.unit ||
      (currentGroup.entries.length > 0 &&
        entry.colIndex !==
          currentGroup.entries[currentGroup.entries.length - 1].colIndex + 1)
    ) {
      if (currentGroup) {
        groupedData.push(currentGroup);
      }
      currentGroup = { unit: entry.unit, entries: [] };
    }
    currentGroup.entries.push(entry);
  }

  if (currentGroup) {
    groupedData.push(currentGroup);
  }

  return { excelName, excelLocationPurpose, data: groupedData };
}

export type TransformToSoilLayersType = {
  index: number;
  excelName: string;
  excelLocationPurpose: string;
  location_group_id: string;
  layers: SoilLayerType[];
  max_depth: number;
  end_of_borehole: number;
};

export function transformToSoilLayers(
  data: LayerOutput,
  index: number,
): TransformToSoilLayersType {
  const soilLayers: SoilLayerType[] = [];
  let max_depth = 10;
  let end_of_borehole = 10;

  for (const layer of data.data) {
    for (const entry of layer.entries) {
      if (entry.key === "z_bot" || entry.unit === "") {
        // Skip entries with key "z_bot"
        max_depth =
          typeof entry.value === "string"
            ? parseFloat(entry.value)
            : entry.value;
        max_depth = Math.round(max_depth * 100) / 100;
        continue;
      }
      if (entry.key === "z_eob") {
        end_of_borehole =
          typeof entry.value === "string"
            ? parseFloat(entry.value)
            : entry.value;
        end_of_borehole = Math.round(end_of_borehole * 100) / 100;
        continue;
      }

      // Determine layer type based on the key
      const layerType = entry.key.includes("sub")
        ? LayerTypeSchema.Values.SUBLAYER
        : LayerTypeSchema.Values.LAYER;

      // Create a SoilLayerType object and validate it with soilLayerSchema
      const soilLayer = soilLayerSchema.parse({
        depth: typeof entry.value === "number" ? entry.value : 0,
        soil_unit_id: layer.unit,
        layer_type: LayerTypeSchema.parse(layerType),
      });

      soilLayers.push(soilLayer);
    }
  }

  soilLayers.sort((a, b) => a.depth - b.depth);

  return {
    index,
    excelName: data.excelName,
    excelLocationPurpose: data.excelLocationPurpose,
    location_group_id: "",
    layers: soilLayers,
    max_depth,
    end_of_borehole,
  };
}

export function reconcileLocationGroupWithLocationGroupID(
  group: LocationGroup,
  locationGroupMapped: Record<string, string>,
): LocationGroup {
  const locationGroupReconciled = {
    ...group,
    location_group_id: locationGroupMapped[group.name] || "",
  };

  return locationGroupReconciled;
}
