import { useState } from "react";
import { Upload } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { NButton, NCard, NCardContent } from "@ngi/react-component";
import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { PageContentHeader } from "src/components/PageContentHeader";
import { useRevisionLocations } from "src/hooks/useRevisionLocations";
import { HorizonTable } from "src/pages/revisionHorizons/HorizonTable";
import { HorizonImporter } from "src/pages/revisionOverview/HorizonImport";
import { CreateOrUpdateSoilUnit } from "src/pages/revisionSoilUnits/CreateOrUpdateSoilUnit";
import {
  getRevisionHorizonQuery,
  getRevisionLocationGroupQuery,
  getRevisionSoilUnitQuery,
} from "src/queries/queries";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/horizons",
)({
  component: HorizonPage,
});

function HorizonPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();
  const [open, setOpen] = useState(false);

  const { locations } = useRevisionLocations({
    project_id,
    phase_id,
    revision_id,
  });

  const { data: locationGroups } = useQuery(
    getRevisionLocationGroupQuery(revision_id),
  );

  const { data: soilUnits } = useQuery(getRevisionSoilUnitQuery(revision_id));

  const {
    isPending,
    isError,
    data: horizons,
  } = useQuery(getRevisionHorizonQuery(revision_id));

  if (isPending) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent
      navigation={
        <ProjectNavigation
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
        />
      }
    >
      <Box className="max-w-wider mx-auto">
        <PageContentHeader
          title="Horizons"
          actions={
            <NButton
              startIcon={<Upload />}
              variant="text"
              onClick={() => setOpen(true)}
            >
              Import horizons
            </NButton>
          }
        />
        <NCard className="mx-auto my-4 max-w-6xl">
          <NCardContent className="!p-0">
            {soilUnits && horizons && horizons.length > 0 ? (
              <HorizonTable
                revision_id={revision_id}
                data={horizons}
                soilUnits={soilUnits}
              />
            ) : (
              <div className="p-4 text-center">
                <Typography variant="body1">
                  No horizons found. Import first horizons by clicking the
                  button above.
                </Typography>
              </div>
            )}
          </NCardContent>
        </NCard>
        {open && (
          <HorizonImporter
            revision_id={revision_id}
            horizons={horizons}
            locations={locations}
            locationGroups={locationGroups}
            open={open}
            setOpen={setOpen}
          />
        )}
      </Box>
    </AppContent>
  );
}
