import { TableBody, TableCell, TableRow } from "@mui/material";
import { flexRender, Table as ReactTable } from "@tanstack/react-table";

import {
  getColumnSize,
  getCommonPinningStyles,
} from "src/components/common/datatable/datatable-utils";

type DatatableBodyProps<T> = {
  table: ReactTable<T>;
};

export const DatatableBody = <T,>({ table }: DatatableBodyProps<T>) => (
  <TableBody className="Datatable-Body">
    {table.getRowModel().rows.map((row, i) => (
      <TableRow
        className={"Datatable-Row " + (row.getIsSelected() ? " Selected" : "")}
        key={`tbody-row-${row.id}-${i}`}
      >
        {row.getVisibleCells().map((cell, i) => (
          <TableCell
            className="Datatable-Cell"
            key={`tbody-cell-${cell.id}-${i}`}
            style={{ ...getCommonPinningStyles(cell.column) }}
            sx={{
              width: getColumnSize(cell.column),
              "& > div": {
                width: "inherit",
                textOverflow: "ellipsis",
              },
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);
