import { useMemo } from "react";
import { KeyboardArrowRight } from "@mui/icons-material";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import {
  getAppConfig,
  getProjectPhasesQuery,
  getProjectQuery,
  getRevisionQuery,
} from "src/queries/queries";

import "./AppHeader.scss";

type Props = {
  projectID?: string;
  phaseID?: string;
  revisionID?: string;
};

export const AppHeader = ({ projectID, phaseID, revisionID }: Props) => {
  const { data: config } = useQuery(getAppConfig);
  const { data: project } = useQuery(getProjectQuery(projectID || ""));
  const { data: phases } = useQuery(getProjectPhasesQuery(projectID || ""));
  const { data: revision } = useQuery(
    getRevisionQuery(projectID || "", phaseID || "", revisionID || ""),
  );

  const isValidProject = project && projectID && projectID !== "";
  const isValidPhase = phases && phaseID && phaseID !== "";
  const isValidRevision = revision && revisionID && revisionID !== "";

  const breadcrumbs = useMemo(() => {
    if (!isValidProject) return;
    const phaseName = phases?.find((p) => p.phase_id === phaseID)?.name;
    return (
      <div className="invisible xl:visible px-4 flex-grow overflow-hidden flex gap-2 items-center justify-center">
        <Link to={`/project/${projectID}`} className="min-w-0 overflow-hidden">
          {project?.project_external_id}
        </Link>
        {isValidPhase && (
          <>
            <KeyboardArrowRight />
            <Link
              to={`/project/${projectID}`}
              className="min-w-0 overflow-hidden"
            >
              <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                {phaseName}
              </span>
            </Link>
          </>
        )}
        {isValidRevision && (
          <>
            <KeyboardArrowRight />
            <Link
              to={`/project/${projectID}/phase/${phaseID}/revision/${revisionID}`}
              className="min-w-0 overflow-hidden"
            >
              <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                {revision.name}
              </span>
            </Link>
          </>
        )}
      </div>
    );
  }, [
    isValidProject,
    isValidPhase,
    isValidRevision,
    phaseID,
    phases,
    project?.project_external_id,
    projectID,
    revision,
    revisionID,
  ]);

  return (
    <Box
      className="AppHeader"
      sx={{ flexGrow: 1, height: "50px", paddingBottom: "8px" }}
    >
      <AppBar color="secondary" position="fixed">
        <Toolbar>
          <div className="AppTitle w-80 flex flex-none gap-2 justify-start items-center">
            <Typography className="AppTitle" variant="h6" component="div">
              <Link to="/">FM-SIITE</Link>
            </Typography>

            {config && config.env !== "PROD" && (
              <div className={`env ${config.env}`}>
                {config.env} ENVIRONMENT
              </div>
            )}
          </div>

          {breadcrumbs}

          <div className="AppConfig w-80 flex flex-none gap-4 justify-end items-center">
            {config && config.version && <div>Version {config.version}</div>}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
