import Papa from "papaparse";

import { Horizon } from "src/types/horizon";
import { LocationGroup, LocationGroupHorizon } from "src/types/locationGroup";

type HorizonMetadata = {
  Loc_ID: string;
  Easting: number;
  Northing: number;
  Water_Base: number;
  Water_Top: number;
};

type CSVHorizon = {
  label: string;
  depth: number;
};

type HorizonLocation = HorizonMetadata & {
  horizons: CSVHorizon[];
};

type ParsedData = HorizonMetadata & {
  Loc_ID: string;
  Easting: string;
  Northing: string;
  Water_Base: string;
  Water_Top: string;
  [key: string]: string | number;
};

const horizonMetadataKeys = [
  "Loc_ID",
  "Easting",
  "Northing",
  "Water_Base",
  "Water_Top",
];

function parseHorizonData(data: ParsedData[]): HorizonLocation[] {
  const locations: HorizonLocation[] = [];
  data.forEach((row) => {
    const horizons: CSVHorizon[] = [];
    Object.entries(row).forEach(([key, value]) => {
      if (!horizonMetadataKeys.includes(key)) {
        const depth = parseFloat(value as string);
        if (!isNaN(depth)) {
          horizons.push({ label: key as string, depth });
        }
      }
    });

    horizons.sort((a: CSVHorizon, b: CSVHorizon) => a.depth - b.depth);

    locations.push({
      Loc_ID: row.Loc_ID,
      Easting: parseFloat(row.Easting),
      Northing: parseFloat(row.Northing),
      Water_Top: parseFloat(row.Water_Top),
      Water_Base: parseFloat(row.Water_Base),
      horizons,
    });
  });
  return locations;
}

export type ParsedHorizonReturnData = {
  status: "Success" | "Error";
  data: HorizonLocation[];
  horizons: CSVHorizon[];
  message?: string;
};

export async function parseCSV(
  csvContent: string,
): Promise<ParsedHorizonReturnData> {
  const result = await Papa.parse<ParsedData>(csvContent);

  const isError = result.errors.length > 0;

  const headers = result.data[0];
  const data = result.data.slice(1).map((row) => {
    const obj: ParsedData = {};
    headers.forEach((header, index) => {
      obj[header] = row[index];
    });
    return obj;
  });

  const parsedData = parseHorizonData(data);

  const horizons = parsedData.reduce((acc, cur) => {
    cur.horizons.forEach((horizon) => {
      if (!acc.find((h) => h.label === horizon.label)) {
        acc.push(horizon);
      }
    });
    return acc;
  }, [] as CSVHorizon[]);

  return {
    status: isError ? "Error" : "Success",
    message: isError ? result.errors[0].message : "",
    data: parsedData,
    horizons,
  };
}

const makeHorizonNote = (
  horizon: CSVHorizon,
  revisionHorizonMapping: Record<string, string>,
): LocationGroupHorizon => ({
  horizon_id: revisionHorizonMapping[horizon.label],
  depth: horizon.depth,
});

export type MatchLocationGroupReturn = {
  locationGroups: LocationGroup[];
  matchedCount: number;
};

export function matchLocationGroupBaseOnName(
  horizonData: HorizonLocation[],
  locationGroups: LocationGroup[],
  revisionHorizons: Horizon[],
): MatchLocationGroupReturn {
  const revisionHorizonMapping = revisionHorizons.reduce(
    (acc, cur) => {
      acc[cur.name] = cur.horizon_id;
      return acc;
    },
    {} as Record<string, string>,
  );
  const horizonDataMapping = horizonData.reduce(
    (acc, cur) => {
      acc[cur.Loc_ID] = cur;
      return acc;
    },
    {} as Record<string, HorizonLocation>,
  );

  const matchedLocationGroupCount = locationGroups.reduce((acc, group) => {
    const horizonLocation = horizonDataMapping[group.name];
    if (horizonLocation) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const matchedLocationGroups = locationGroups
    .map((group) => {
      const horizonLocation = horizonDataMapping[group.name];
      if (horizonLocation) {
        return {
          ...group,
          horizons: horizonLocation.horizons.map((horizon) =>
            makeHorizonNote(horizon, revisionHorizonMapping),
          ),
        };
      }
      return group;
    })
    .filter((group) => group.horizons && group.horizons.length > 0);

  return {
    locationGroups: matchedLocationGroups,
    matchedCount: matchedLocationGroupCount,
  };
}
