import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { diff } from "@opentf/obj-diff";
import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";

import { NDialog } from "@ngi/react-component";
import { Datatable } from "src/components/common/datatable/Datatable";
import { rowSelectionColumn } from "src/components/common/datatable/features/rowSelection";
import { useRowSelection } from "src/components/common/datatable/hooks/useRowSelection";
import { HORIZON_COLOR } from "src/definitions/constants";
import { batchUpdateRevisionHorizonMutationQuery } from "src/queries/mutations";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { Horizon } from "src/types/horizon";
import { DeleteHorizonDialog } from "./DeleteHorizon";
import { HorizonColorCell } from "./tableFeatures/HorizonColorCell";
import { SoilUnitCellFactory } from "./tableFeatures/SoilUnitCell";

type Props = {
  revision_id: string;
  data: Horizon[];
  soilUnits: SoilUnitSchemaType[];
};

type BusyStatus = "idle" | "fetching" | "deleting" | "saving";

const columnHelper = createColumnHelper<Horizon>();

export const HorizonTable = ({ revision_id, data, soilUnits }: Props) => {
  const [status, setStatus] = useState<BusyStatus>("idle");
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [localData, setLocalData] = useState<Horizon[]>(
    data.sort((a, b) => a.name.localeCompare(b.name)),
  );

  const [selectedHorizons, setSelectedHorizons] = useState<string[]>([]);
  const [dataHasChanged, setDataHasChanged] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getColorMatchedSoilUnits = useCallback(
    (horizon: Horizon) => {
      // if the color is already a custom one, then return it
      if (horizon.color !== HORIZON_COLOR) return horizon.color;
      // if no matched_soil_units, return HORIZON_COLOR
      if (horizon.matched_soil_units.length === 0) return HORIZON_COLOR;
      // otherwise, return the color of the first soil unit that matches
      const color = soilUnits.find(
        (soilUnit) => soilUnit.soil_unit_id === horizon.matched_soil_units[0],
      )?.color;
      return color || horizon.color;
    },
    [soilUnits],
  );

  const handleUpdateDataFromTable = useCallback(
    (updateFn: (horizons: Horizon[]) => Horizon[]) => {
      const newHorizons = updateFn(localData).map((horizon) => ({
        ...horizon,
        color: getColorMatchedSoilUnits(horizon),
      }));
      console.log("newHorizons: ", newHorizons);

      setLocalData(newHorizons);
      setDataHasChanged(true);
    },
    [getColorMatchedSoilUnits, localData],
  );

  const updateHorizons = useMutation(
    batchUpdateRevisionHorizonMutationQuery(revision_id),
  );

  const handleUpdateHorizons = useCallback(() => {
    if (dataHasChanged) {
      const dataDiff = diff(data, localData);
      if (dataDiff.length > 0) {
        const indexes = [
          ...new Set(dataDiff.map((diff) => diff.p[0]) as number[]),
        ];
        const updatedHorizons = indexes.map((index) => localData[index]);
        if (updatedHorizons.length > 0) {
          setStatus("saving");
          setStatusMessage("Updating horizons...");
          setSnackbarOpen(true);
          updateHorizons.mutate(
            {
              revision_id,
              payload: updatedHorizons,
            },
            {
              onSuccess: () => {
                setDataHasChanged(false);
                setStatus("idle");
                setStatusMessage("");
                setSnackbarOpen(false);
              },
            },
          );
        }
      }
    }
  }, [data, dataHasChanged, localData, revision_id, updateHorizons]);

  const handleDeleteHorizonStart = () => {
    setStatusMessage("Deleting horizons...");
    setStatus("deleting");
    setSnackbarOpen(true);
  };

  const handleDeleteHorizonEnd = () => {
    setStatusMessage("Fetching horizons from the server...");
    setStatus("fetching");
  };

  const columns = useMemo(
    () => [
      rowSelectionColumn(),
      columnHelper.accessor("color", {
        cell: HorizonColorCell,
        header: "Color",
        size: 50,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("name", {
        cell: (row) => row.getValue(),
        header: "Horizon name",
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("matched_soil_units", {
        cell: SoilUnitCellFactory(soilUnits),
        header: "Matched soil units",
        enableColumnFilter: false,
      }),
    ],
    [soilUnits],
  );

  const { rowSelection, handleOnRowSelectionChange } = useRowSelection<Horizon>(
    {
      data: data,
      rowKey: "horizon_id",
      initialRowSelection: selectedHorizons,
      onRowSelectionChange: setSelectedHorizons,
    },
  );

  useEffect(() => {
    if (status === "fetching" && data.length !== localData.length) {
      setLocalData(data);
      setStatus("idle");
      setSnackbarOpen(false);
      setStatusMessage("");
    }
  }, [data, localData.length, status]);

  return (
    <Box>
      <Datatable
        data={localData}
        columns={columns}
        usePagination={localData.length > 10}
        useRowSelection
        rowSelection={rowSelection}
        setRowSelection={handleOnRowSelectionChange}
        getRowId={(row: Horizon) => row.horizon_id}
        editable
        setData={handleUpdateDataFromTable}
      />
      <div className="mt-8 pb-4 flex items-center justify-center space-x-4">
        <Button
          onClick={handleUpdateHorizons}
          color="primary"
          disabled={!dataHasChanged || status !== "idle"}
        >
          Save changes
        </Button>
        <Button
          onClick={() => setDeleteDialogOpen(true)}
          color="error"
          disabled={selectedHorizons.length === 0 || status !== "idle"}
        >
          Delete horizons
        </Button>
      </div>
      {savingData && (
        <NDialog
          open={savingData}
          onClose={() => setSavingData(false)}
          dialogTitle="Saving horizons to the database"
        >
          <CircularProgress />
        </NDialog>
      )}
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen}
        >
          <Alert
            severity="info"
            variant="filled"
            icon={
              <CircularProgress
                size={28}
                variant="indeterminate"
                sx={{ color: "white" }}
              />
            }
          >
            <Typography variant="h6">{statusMessage}</Typography>
          </Alert>
        </Snackbar>
      )}
      {deleteDialogOpen && (
        <DeleteHorizonDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          revision_id={revision_id}
          horizons={selectedHorizons}
          onDeleteStart={handleDeleteHorizonStart}
          onDeleteEnd={handleDeleteHorizonEnd}
        />
      )}
    </Box>
  );
};
