import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  flexRender,
  HeaderGroup,
  Table as ReactTable,
} from "@tanstack/react-table";

import {
  getColumnSize,
  getCommonPinningStyles,
} from "src/components/common/datatable/datatable-utils";
import { ColumnFilter } from "src/components/common/datatable/features/ColumnFilter";

type DatatableHeaderProps<T> = {
  table: ReactTable<T>;
};

export const DatatableHeader = <T,>({ table }: DatatableHeaderProps<T>) => (
  <TableHead className="Datatable-Header">
    {table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => (
      <TableRow className="Datatable-Row" key={headerGroup.id}>
        {headerGroup.headers.map((header, i) => (
          <TableCell
            className="Datatable-HeaderCell"
            key={`thead-row-${header.id}-${i}`}
            colSpan={header.colSpan}
            style={{ ...getCommonPinningStyles(header.column) }}
            sx={{
              width: getColumnSize(header.column),
              "& > div": {
                width: "inherit",
                textOverflow: "ellipsis",
              },
            }}
          >
            {header.isPlaceholder ? null : (
              <>
                <div>
                  <TableSortLabel
                    active={header.column.getIsSorted() !== false}
                    direction={header.column.getIsSorted() || "asc"}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getIsSorted() ? (
                      <Box component="span" sx={visuallyHidden}>
                        {header.column.getIsSorted() === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </div>
                {header.column.getCanFilter() ? (
                  <div>
                    <ColumnFilter column={header.column} table={table} />
                  </div>
                ) : null}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableHead>
);
