type DatatableDebugProps<T> = {
  tableState: any; // Replace with a more precise type if needed
  data: T[];
};

export const DatatableDebug = <T,>({
  tableState,
  data,
}: DatatableDebugProps<T>) => (
    <div>
      <label>Row Selection State:</label>
      <pre>{JSON.stringify(tableState.rowSelection, null, 2)}</pre>
      <label>Table Data State:</label>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
