import { useMemo } from "react";
import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { EditableTextCell } from "src/components/common/datatable/features/editableCells/EditableTextCell";
import { SoilLayerType } from "src/schemas/soilLayerSchema";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { DepthCell } from "./tableFeatures/DepthCell";
import { EditableLayerActionCell } from "./tableFeatures/EditableLayerActionCell";
import { LayerTypeCell } from "./tableFeatures/LayerTypeCell";
import { SoilUnitCellFactory } from "./tableFeatures/SoilUnitCell";
import { SoilUnitColorCellFactory } from "./tableFeatures/SoilUnitColorCell";

type Props = {
  layers: SoilLayerType[];
  soilUnits: SoilUnitSchemaType[];
  updateLayers: (
    updateFn: (layers: SoilLayerType[]) => SoilLayerType[],
  ) => void;
};

const columnHelper = createColumnHelper<SoilLayerType>();

export const EditableLayerTable = ({
  layers,
  updateLayers,
  soilUnits,
}: Props) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor("soil_unit_id", {
        cell: SoilUnitColorCellFactory(soilUnits, layers),
        header: "",
        size: 40,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("depth", {
        cell: DepthCell,
        header: "Depth [m]",
        size: 80,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("soil_unit_id", {
        cell: SoilUnitCellFactory(soilUnits),
        header: "Soil unit",
        size: 100,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("layer_type", {
        cell: LayerTypeCell,
        header: "Layer type",
        size: 200,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("description_overide", {
        cell: EditableTextCell,
        header: "Description",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("depth", {
        cell: EditableLayerActionCell,
        header: "",
        size: 40,
        sortUndefined: "last",
        sortDescFirst: false,
        enableColumnFilter: false,
      }),
    ],
    [layers, soilUnits],
  );

  return (
    <Box>
      <Datatable
        data={layers}
        columns={columns}
        setData={updateLayers}
        usePagination={layers.length > 10}
        editable
      />
    </Box>
  );
};
