import { Box } from "@mui/material";

type Props = {
  color: string;
  classNames?: string;
};

export const ColorBox = ({ color, classNames }: Props) => (
  <Box
    className={`!h-8 !w-8 rounded-full ${classNames || ""}`}
    sx={{ background: color }}
  ></Box>
);
