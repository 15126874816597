import { useState } from "react";
import { Table } from "@mui/material";
import {
  ColumnDef,
  ColumnPinningState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowData,
  RowSelectionState,
  SortingState,
  TableOptions,
  TableState,
  useReactTable,
} from "@tanstack/react-table";

import { DatatableDebug } from "./DatatabeDebug";
import { debugUpdateData, defaultSetData, useSkipper } from "./datatable-utils";
import { DatatableBody } from "./DatatableBody";
import { DEFAULT_COLUMN_SIZE } from "./DatatableConfig";
import { DatatableHeader } from "./DatatableHeader";
import { DatatablePagination } from "./DatatablePagination";

import "./Datatable.scss";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    removeRow: (row: number) => void;
    updateData: <K extends keyof TData>(
      rowIndex: number,
      columnId: string,
      value: TData[K],
    ) => void;
  }
}

type Props<T> = Omit<TableOptions<T>, "getCoreRowModel"> & {
  data: T[];
  setData?: (updateFn: (layers: T[]) => T[]) => void;
  columns: ColumnDef<T, keyof T>[];
  editable?: boolean;
  debug?: boolean;
  usePagination?: boolean;
  useRowSelection?: boolean;
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  sorting?: SortingState;
  setSorting?: OnChangeFn<SortingState>;
  getRowId?: (row: T) => string;
  pinnedColumns?: ColumnPinningState;
};

export const Datatable = <T,>({
  data,
  columns,
  editable = false,
  setData = defaultSetData,
  debug = false,
  usePagination = false,
  useRowSelection = false,
  rowSelection,
  setRowSelection,
  sorting,
  setSorting,
  getRowId,
  pinnedColumns = { left: [], right: [] },
}: Props<T>) => {
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const [columnPinning, setColumnPinning] =
    useState<ColumnPinningState>(pinnedColumns);

  const state: Partial<TableState> = { columnPinning };

  if (sorting) {
    state["sorting"] = sorting;
  }

  if (useRowSelection) {
    state["rowSelection"] = rowSelection;
  }

  const tableOptions: TableOptions<T> = {
    data,
    columns,
    defaultColumn: { ...DEFAULT_COLUMN_SIZE },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enablePinning: true,
    // onGlobalFilterChange: setGlobalFilter,
    getRowId: getRowId,
    state: state,
    autoResetPageIndex,
    meta: {
      removeRow(rowIndex: number) {
        const setFilterFunc = (old: T[]) =>
          old.filter((_row: T, index: number) => index !== rowIndex);
        setData(setFilterFunc);
      },
      updateData(rowIndex, columnId, value) {
        if (debug) debugUpdateData(rowIndex, columnId, value);

        skipAutoResetPageIndex(); // Skip page index reset until after next rerender

        if (editable) {
          setData((old: T[]) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        }
      },
    },
    debugTable: debug,
  };

  const table = useReactTable(tableOptions);

  return (
    <>
      <div>
        <Table
          className="Datatable"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <DatatableHeader table={table} />
          <DatatableBody table={table} />
        </Table>
      </div>
      {usePagination && (
        <DatatablePagination table={table} dataLength={data.length} />
      )}
      {debug && <DatatableDebug tableState={table.getState()} data={data} />}
    </>
  );
};
