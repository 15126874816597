import axios from "axios";

import { CreateHorizon, Horizon } from "src/types/horizon";
import { setURL } from "src/utils/api";

export type CreateHorizonProps = {
  revision_id: string;
  payload: Horizon;
};

// ╭─────────────────────────────────────────────────────────╮
// │                  Batch create horizon                   │
// ╰─────────────────────────────────────────────────────────╯
export type BatchCreateHorizonProps = {
  revision_id: string;
  payload: CreateHorizon[];
};

export const batchCreateHorizon = async ({
  revision_id,
  payload,
}: BatchCreateHorizonProps) => {
  const url = setURL(`revision/${revision_id}/horizons`);
  await axios.post(url, { horizons: payload });
};

// ╭─────────────────────────────────────────────────────────╮
// │                  Batch update horizon                   │
// ╰─────────────────────────────────────────────────────────╯
export type BatchUpdateHorizonProps = {
  revision_id: string;
  payload: Horizon[];
};

export const batchUpdateHorizon = async ({
  revision_id,
  payload,
}: BatchUpdateHorizonProps) => {
  const url = setURL(`revision/${revision_id}/horizons`);
  await axios.put(url, { revision_id, horizons: payload });
};

// ╭─────────────────────────────────────────────────────────╮
// │                  Single update horizon                  │
// ╰─────────────────────────────────────────────────────────╯
export type UpdateHorizonProps = {
  soil_unit_id: string;
  payload: Horizon;
};

export const updateHorizon = async ({
  soil_unit_id,
  payload,
}: UpdateHorizonProps) => {
  const url = setURL(`soil_unit/${soil_unit_id}`);
  await axios.put(url, payload);
};

// ╭─────────────────────────────────────────────────────────╮
// │                  Single delete horizon                  │
// ╰─────────────────────────────────────────────────────────╯
export type DeleteHorizonProps = {
  horizon_id: string;
};

export const deleteHorizon = async ({ horizon_id }: DeleteHorizonProps) => {
  const url = setURL(`horizon/${horizon_id}`);
  await axios.delete(url);
};

// ╭─────────────────────────────────────────────────────────╮
// │                  Get revision horizons                  │
// ╰─────────────────────────────────────────────────────────╯
type GetRevisionHorizonProps = {
  revision_id: string;
};

export const getRevisionHorizons = async ({
  revision_id,
}: GetRevisionHorizonProps): Promise<Horizon[]> => {
  const url = setURL(`revision/${revision_id}/horizons`);
  const response = await axios.get(url);
  if (response.status === 200) {
    return response.data.horizons.map((horizon: Horizon) => ({
      ...horizon,
      matched_soil_units: horizon.matched_soil_units || [],
    }));
  }
  return response.data;
};
