import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";

import { ColorBox } from "src/components/ColorBox";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { Horizon } from "src/types/horizon";
import { TableCustomCell } from "src/types/table";

type Props = TableCustomCell<Horizon, Horizon["matched_soil_units"]>;

export const SoilUnitCellFactory = (soilUnits: SoilUnitSchemaType[]) => {
  const SoilUnitCell = ({ getValue, row, column, table }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
    const options = soilUnits.map((soilUnit) => ({
      value: soilUnit.soil_unit_id,
      label: soilUnit.name,
      color: soilUnit.color,
    }));

    const selectedValues = (getValue() as string[]) || [];
    const updateData = table.options.meta?.updateData;

    const handleChange = (event: SelectChangeEvent<string[]>) => {
      if (updateData) {
        updateData(row.index, column.id, event.target.value as string[]);
      }
    };

    const handleClickOnChipToAssignColor = (color: string | undefined) => {
      if (updateData) {
        if (color) {
          updateData(row.index, "color", color);
        }
      }
    };

    const handleDeleteChip = (valueToDelete: string) => {
      const updatedValues = selectedValues.filter((v) => v !== valueToDelete);
      if (updateData) {
        updateData(row.index, column.id, updatedValues);
      }
    };

    const handleDialogClose = () => {
      setIsDialogOpen(false);
    };

    const MAX_CHIPS_DISPLAY = 5;

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <div className="flex items-center w-full justify-between">
          {/* Chips for selected soil units */}
          <Box display="flex" flexWrap="nowrap" gap={1} overflow="hidden">
            {selectedValues.slice(0, MAX_CHIPS_DISPLAY).map((value) => {
              const option = options.find((opt) => opt.value === value);
              return (
                <Chip
                  avatar={<ColorBox color={option?.color || "#777"} />}
                  key={value}
                  label={option?.label || value}
                  onClick={() => handleClickOnChipToAssignColor(option?.color)}
                  onDelete={() => handleDeleteChip(value)}
                />
              );
            })}

            {selectedValues.length > MAX_CHIPS_DISPLAY && (
              <Chip
                label={`+${selectedValues.length - MAX_CHIPS_DISPLAY} more`}
                onClick={() => setIsDialogOpen(true)}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>

          {/* Icon button to open dialog */}
          <div className="self-end">
            <Tooltip title="Select soil units" placement="top">
              <IconButton
                onClick={() => setIsDialogOpen(true)}
                size="small"
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* Dialog containing the Select dropdown */}
        <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth>
          <DialogTitle>Select Soil Units</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <Select
                multiple
                value={selectedValues}
                onChange={handleChange}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const option = options.find((opt) => opt.value === value);
                      return option?.label || value;
                    })
                    .join(", ")
                }
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={selectedValues.includes(option.value)} />
                    <div className="flex space-x-4 w-full items-center">
                      <ColorBox color={option.color} classNames="mr-4" />
                      {option.label}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  SoilUnitCell.displayName = "SoilUnitCell";
  return SoilUnitCell;
};
