import { useMemo } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { AppContent } from "src/components/app/AppContent";
import { ProjectNavigation } from "src/components/app/AppNavigation";
import { ErrorComponent } from "src/components/ErrorComponent";
import { LoadingComponent } from "src/components/LoadingComponent";
import { PageContentHeader } from "src/components/PageContentHeader";
import { RevisionNotEnoughData } from "src/components/RevisionNotEnoughData";
import { LocationGroupWithLayerTable } from "src/pages/revisionSoilLayering/LocationGroupWithLayerTable";
import {
  getRevisionHorizonQuery,
  getRevisionLocationGroupQuery,
  getRevisionLocationsQuery,
  getRevisionSoilUnitQuery,
} from "src/queries/queries";

export const Route = createFileRoute(
  "/project/$project_id/phase/$phase_id/revision/$revision_id/soil-layering",
)({
  component: SoilLayeringPage,
});

function SoilLayeringPage() {
  const { project_id, phase_id, revision_id } = Route.useParams();
  const {
    isPending: isLocationsPending,
    isError: isLocationsError,
    data: locations,
  } = useQuery(getRevisionLocationsQuery(project_id, phase_id, revision_id));

  const {
    isPending: isLocationGroupsPending,
    isError: isLocationGroupsError,
    data: locationGroups,
  } = useQuery(getRevisionLocationGroupQuery(revision_id));

  const {
    isPending: isSoilUnitsPending,
    isError: isSoilUnitsError,
    data: soilUnits,
  } = useQuery(getRevisionSoilUnitQuery(revision_id));

  const {
    isPending: isHorizonsPending,
    isError: isHorizonsError,
    data: horizons,
  } = useQuery(getRevisionHorizonQuery(revision_id));

  const locationNameMapping = locations
    ? locations.reduce(
        (acc, location) => {
          acc[location.siite_location_id] = location.name;
          return acc;
        },
        {} as Record<string, string>,
      )
    : {};

  const isPending = useMemo(
    () =>
      isLocationsPending ||
      isLocationGroupsPending ||
      isSoilUnitsPending ||
      isHorizonsPending,
    [
      isHorizonsPending,
      isLocationGroupsPending,
      isLocationsPending,
      isSoilUnitsPending,
    ],
  );

  const isError = useMemo(
    () =>
      isLocationsError ||
      isLocationGroupsError ||
      isSoilUnitsError ||
      isHorizonsError,
    [
      isHorizonsError,
      isLocationGroupsError,
      isLocationsError,
      isSoilUnitsError,
    ],
  );

  if (isPending) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <AppContent
      navigation={
        <ProjectNavigation
          project_id={project_id}
          phase_id={phase_id}
          revision_id={revision_id}
        />
      }
    >
      <Box className="max-w-wider mx-auto">
        <PageContentHeader title="Global unit layers" />
        {locationGroups && locationGroups.length > 0 ? (
          <LocationGroupWithLayerTable
            project_id={project_id}
            revision_id={revision_id}
            locationNameMapping={locationNameMapping}
            locationGroups={locationGroups}
            soilUnits={soilUnits}
            horizons={horizons || []}
          />
        ) : (
          <RevisionNotEnoughData
            project_id={project_id}
            phase_id={phase_id}
            revision_id={revision_id}
            locations={locations || []}
            locationGroups={locationGroups}
            scope="soilLayering"
          />
        )}
      </Box>
    </AppContent>
  );
}
