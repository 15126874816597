import { useState } from "react";
import {
  ArrowCircleRight,
  CheckCircle,
  Error,
  FileDownload,
  FileUpload,
} from "@mui/icons-material";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import { NButton } from "@ngi/react-component";
import {
  getRevisionExcelGlobalUnitLayerFileQuery,
  getRevisionExcelParentFileQuery,
} from "src/queries/queries";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { Horizon } from "src/types/horizon";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import { ExcelImporter } from "./ExcelImport";
import { HorizonImporter } from "./HorizonImport";

const downloadFile = (data: Blob, fileName: string) => {
  console.log("data", data);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Specify the file name
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up the DOM
};

type StepProps = {
  valid: boolean;
  disabled?: boolean;
  loading: boolean;
  title: string;
  betaBadge?: boolean;
  children: React.ReactNode;
  to: React.ReactNode;
};

const Step = ({
  valid,
  betaBadge = false,
  disabled = false,
  loading = false,
  title,
  to,
  children,
}: StepProps) => (
  <Paper className={`p-2 ${disabled ? "!bg-neutral-100" : ""}`}>
    <Box className="w-full flex flex-row items-center">
      <Box className="StepNum w-8">
        {loading ? (
          <CircularProgress size="30px" className="ml-1" />
        ) : valid ? (
          <CheckCircle color="success" fontSize="large" />
        ) : (
          <Error color={disabled ? "disabled" : "warning"} fontSize="large" />
        )}
      </Box>
      <Box className="flex flex-col grow pl-4">
        <div className="flex space-x-2 items-center">
          <Typography variant="h6">{title}</Typography>
          {betaBadge && (
            <span className="rounded-lg bg-warning px-2 text-white text-sm">
              BETA
            </span>
          )}
        </div>
        {loading ? (
          <Typography>Fetching data from the server...</Typography>
        ) : (
          <Box>{children}</Box>
        )}
      </Box>
      {!disabled && <Box className="ml-12">{to}</Box>}
    </Box>
  </Paper>
);

type LinkToProps = Pick<Props, "project_id" | "phase_id" | "revision_id"> & {
  to: string;
};
const LinkTo = ({ to, project_id, phase_id, revision_id }: LinkToProps) => (
  <Link to={to} params={{ project_id, phase_id, revision_id }}>
    <ArrowCircleRight color="primary" fontSize="large" />
  </Link>
);

type Props = {
  project_id: string;
  phase_id: string;
  revision_id: string;
  locations: Location[];
  locationGroups: LocationGroup[];
  soilUnits: SoilUnitSchemaType[];
  horizons: Horizon[];
  isLocationsPending: boolean;
  isLocationGroupPending: boolean;
  isSoilUnitsPending: boolean;
  isHorizonsPending: boolean;
};

export const RevisionOverviewSteps = ({
  project_id,
  phase_id,
  revision_id,
  locations,
  locationGroups,
  soilUnits,
  horizons,
  isLocationsPending,
  isLocationGroupPending,
  isSoilUnitsPending,
  isHorizonsPending,
}: Props) => {
  const isLocationValid = locations && locations.length > 0;
  const isLocationGroupValid = locationGroups && locationGroups.length > 0;
  const isSoilUnitValid = soilUnits && soilUnits.length > 1;
  const isHorizonValid = horizons && horizons.length > 0;

  const [showImportParentFile, setShowImportParentFile] = useState(false);
  const [showImportHorizonFile, setShowImportHorizonFile] = useState(false);

  const downloadParentFile = useQuery(
    getRevisionExcelParentFileQuery(revision_id),
  );

  const downloadGlobalUnitLayerFile = useQuery(
    getRevisionExcelGlobalUnitLayerFileQuery(revision_id),
  );

  const handleDownload = async (scope: "ParentFile" | "GlobalUnitLayer") => {
    const filename =
      scope === "ParentFile" ? "parent_file.xlsx" : "_global_unit_layer.xlsx";
    const { data } =
      scope === "ParentFile"
        ? await downloadParentFile.refetch()
        : await downloadGlobalUnitLayerFile.refetch();
    if (data) {
      downloadFile(data, filename); // Trigger the download
    }
  };

  return (
    <Box>
      <Typography variant="h4">Revision overview</Typography>

      <Box className="flex flex-col gap-4 pt-4">
        <Step
          valid={isLocationValid}
          loading={isLocationsPending}
          disabled={isLocationsPending}
          title="Locations"
          to={
            <LinkTo
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/locations"
              project_id={project_id}
              phase_id={phase_id}
              revision_id={revision_id}
            />
          }
        >
          {locations && isLocationValid ? (
            <div>{locations.length} locations imported</div>
          ) : (
            <div>
              <Typography variant="body1">No locations</Typography>
              <Typography variant="body1">
                You need to import first locations from FieldManager by clicking
                the button on the right.
              </Typography>
            </div>
          )}
        </Step>
        <Step
          valid={isLocationGroupValid}
          loading={isLocationGroupPending}
          disabled={!isLocationValid}
          title="Location groups"
          to={
            <LinkTo
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/location-groups"
              project_id={project_id}
              phase_id={phase_id}
              revision_id={revision_id}
            />
          }
        >
          {locationGroups && isLocationGroupValid ? (
            <div>{locationGroups.length} location groups</div>
          ) : (
            <div>
              <Typography variant="body1">No location groups</Typography>
            </div>
          )}
        </Step>
        <Step
          valid={isSoilUnitValid}
          loading={isSoilUnitsPending}
          disabled={!isLocationValid}
          title="Soil units"
          to={
            <LinkTo
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/soil-units"
              project_id={project_id}
              phase_id={phase_id}
              revision_id={revision_id}
            />
          }
        >
          {soilUnits && isSoilUnitValid ? (
            <div>
              {soilUnits.length} soil units (including default soil unit)
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                Only the default soil unit has been defined. You may want to
                create more.
              </Typography>
            </div>
          )}
        </Step>
        <Step
          valid={isHorizonValid}
          loading={isHorizonsPending}
          disabled={!isHorizonValid}
          title="Horizons"
          to={
            <LinkTo
              to="/project/$project_id/phase/$phase_id/revision/$revision_id/horizons"
              project_id={project_id}
              phase_id={phase_id}
              revision_id={revision_id}
            />
          }
        >
          {horizons && isHorizonValid ? (
            <div>{horizons.length} horizons defined for this revision</div>
          ) : (
            <div>
              <Typography variant="body1">
                No horizons defined. Use the horizon import button to add
                horizons.
              </Typography>
            </div>
          )}
        </Step>
      </Box>

      <Typography variant="h4" className="pt-4">
        Import / Export
      </Typography>

      <Box className="flex flex-col gap-4 pt-4">
        <Step
          valid={isLocationValid && isLocationGroupValid}
          loading={
            isLocationsPending || isLocationGroupPending || isSoilUnitsPending
          }
          disabled={!isLocationValid || !isLocationGroupValid}
          title="Export to Excel"
          to={
            <>
              <NButton
                startIcon={<FileDownload />}
                variant="text"
                onClick={() => handleDownload("ParentFile")}
                disabled={!isLocationValid || !isLocationGroupValid}
              >
                Parent file
              </NButton>
              <NButton
                startIcon={<FileDownload />}
                variant="text"
                onClick={() => handleDownload("GlobalUnitLayer")}
                disabled={!isLocationValid || !isLocationGroupValid}
              >
                Global unit layer file
              </NButton>
            </>
          }
        >
          {isLocationValid && isLocationGroupValid ? (
            <div>
              Export the Excel parent_file to continue the work with the python
              scripts
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                To export the parent_file, you need to have imported locations
                and defined location groups.
              </Typography>
            </div>
          )}
        </Step>
        <Step
          valid={isLocationValid}
          loading={
            isLocationsPending || isLocationGroupPending || isSoilUnitsPending
          }
          disabled={!isLocationValid}
          title="Import from Excel"
          betaBadge
          to={
            <>
              <NButton
                startIcon={<FileUpload />}
                variant="text"
                onClick={() => setShowImportParentFile(true)}
                disabled={!isLocationValid}
              >
                Import Parent file
              </NButton>
            </>
          }
        >
          {isLocationValid ? (
            <div>
              Import the Excel parent_file to update the location groups
              layering based on the excel content
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                To import the parent_file, you need to have imported locations
                already.
              </Typography>
            </div>
          )}
        </Step>
        <Step
          valid={isLocationGroupValid}
          loading={
            isLocationsPending || isLocationGroupPending || isSoilUnitsPending
          }
          disabled={!isLocationGroupValid}
          title="Import horizons from csv"
          betaBadge
          to={
            <>
              <NButton
                startIcon={<FileUpload />}
                variant="text"
                onClick={() => setShowImportHorizonFile(true)}
                disabled={!isLocationGroupValid || isHorizonsPending}
              >
                Import horizon file
              </NButton>
            </>
          }
        >
          {isLocationGroupValid ? (
            <div>
              Import the horizon csv file to update the location groups horizons
              based on the csv content
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                To import the horizons, you need to have defined location groups
                already.
              </Typography>
            </div>
          )}
        </Step>
      </Box>
      {showImportParentFile && (
        <ExcelImporter
          revision_id={revision_id}
          soilUnits={soilUnits}
          locations={locations}
          locationGroups={locationGroups}
          open={showImportParentFile}
          setOpen={setShowImportParentFile}
        />
      )}
      {showImportHorizonFile && (
        <HorizonImporter
          revision_id={revision_id}
          locations={locations}
          horizons={horizons}
          locationGroups={locationGroups}
          open={showImportHorizonFile}
          setOpen={setShowImportHorizonFile}
        />
      )}
    </Box>
  );
};
