import { useCallback, useMemo, useState } from "react";
import { Check, Error, FileDownload } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { HORIZON_COLOR } from "src/definitions/constants";
import {
  matchLocationGroupBaseOnName,
  MatchLocationGroupReturn,
  parseCSV,
  ParsedHorizonReturnData,
} from "src/methods/horizon-csv-import/horizon-csv-parser";
import {
  batchCreateRevisionHorizonMutationQuery,
  batchUpdateRevisionLocationGroupMutationQuery,
} from "src/queries/mutations";
import { Horizon } from "src/types/horizon";
import { LocationGroup } from "src/types/locationGroup";
import { Location } from "src/types/locations";
import { sleep } from "src/utils/sleep";

type Props = {
  revision_id: string;
  horizons: Horizon[];
  locations: Location[];
  locationGroups: LocationGroup[];
  open: boolean;
  setOpen: (v: boolean) => void;
};

type MatchingMethod = "name" | "coordinates";
type BusyStatus = "idle" | "mutating" | "error" | "success";

export const HorizonImporter = ({
  revision_id,
  horizons,
  locations,
  locationGroups,
  open,
  setOpen,
}: Props) => {
  console.log("locationGroups", locationGroups);
  const [busyStatus, setBusyStatus] = useState<BusyStatus>("idle");
  const [busyMessage, setBusyMessage] = useState<string>("");

  const [activeStep, setActiveStep] = useState(0);

  const [matchingMethod, setMatchingMethod] = useState<MatchingMethod>("name");

  const [matchedLocationGroups, setMatchedLocationGroups] =
    useState<MatchLocationGroupReturn>({ locationGroups: [], matchedCount: 0 });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setBusyStatus("idle");
    setCsvFile(null);
    setActiveStep(0);
  };

  const [csvFile, setCsvFile] = useState<File | null>(null);

  const [csvData, setCsvData] = useState<ParsedHorizonReturnData | null>(null);

  const horizonStats = useMemo(() => {
    const ans = {
      existingHorizons: [],
      foundHorizonNames: [],
      notFoundHorizonNames: [],
      hasMissingHorizons: false,
    };

    if (!csvData) return ans;

    const horizonNames = horizons.map((horizon) => horizon.name);
    const csvHorizonNames = csvData.horizons.map((horizon) => horizon.label);

    // Find items in csvHorizonNames that are found in horizonNames
    const foundHorizonNames = csvHorizonNames.filter((name) =>
      horizonNames.includes(name),
    );

    // Find items in csvHorizonNames that are NOT found in horizonNames
    const notFoundHorizonNames = csvHorizonNames.filter(
      (name) => !horizonNames.includes(name),
    );

    return {
      existingHorizons: horizonNames,
      foundHorizonNames,
      notFoundHorizonNames,
      hasMissingHorizons: notFoundHorizonNames.length > 0,
    };
  }, [csvData, horizons]);

  const handleLocationGroupMatching = useCallback(
    (csvData: ParsedHorizonReturnData, matchingMethod: MatchingMethod) => {
      if (!csvData) return;
      if (matchingMethod === "name") {
        const matchedGroup = matchLocationGroupBaseOnName(
          csvData.data,
          locationGroups,
          horizons,
        );
        setMatchedLocationGroups(matchedGroup);
      }
    },
    [horizons, locationGroups],
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as MatchingMethod;
      setMatchingMethod(value);
      if (csvData) {
        handleLocationGroupMatching(csvData, value);
      }
    },
    [csvData, handleLocationGroupMatching],
  );

  const handleCSVParsing = useCallback(
    async (content: string) => {
      if (!content) return;
      try {
        const data = await parseCSV(content);
        setCsvData(data);
        handleLocationGroupMatching(data, matchingMethod);
      } catch (error) {
        console.error("Error importing file:", error);
      }
    },
    [handleLocationGroupMatching, matchingMethod],
  );

  const handleFileInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setCsvFile(file);
        const content = await file.text();
        handleCSVParsing(content);
      }
    },
    [handleCSVParsing],
  );

  const actions = useMemo(
    () => (
      <>
        {["idle", "error"].includes(busyStatus) && (
          <NButton onClick={() => setOpen(false)} variant="text">
            Cancel
          </NButton>
        )}
      </>
    ),
    [busyStatus, setOpen],
  );

  const createHorizons = useMutation(
    batchCreateRevisionHorizonMutationQuery(revision_id),
  );

  const handleAddHorizons = useCallback(() => {
    if (!csvData) return;
    const horizons = csvData.horizons;
    if (horizons.length === 0) return;

    setBusyMessage("Creating the missing horizons");
    setBusyStatus("mutating");
    const payload = horizons.map((horizon) => ({
      name: horizon.label,
      color: HORIZON_COLOR,
    }));

    createHorizons.mutate(
      { revision_id, payload },
      {
        onSuccess: async () => {
          setBusyMessage("Successfully created the missing horizons");
          setBusyStatus("success");
          await sleep(2000);
          setBusyStatus("idle");
        },
        onError: () => {
          setBusyStatus("error");
        },
      },
    );
  }, [createHorizons, csvData, revision_id]);

  const updateLocationGroups = useMutation(
    batchUpdateRevisionLocationGroupMutationQuery(revision_id),
  );

  const handleImportHorizonsLocationGroups = useCallback(() => {
    if (!csvData) return;

    const matchedGroup = matchLocationGroupBaseOnName(
      csvData.data,
      locationGroups,
      horizons,
    );
    const groups = matchedGroup.locationGroups;
    if (groups.length === 0) return;
    setBusyMessage("Importing the horizons for the location groups");
    setBusyStatus("mutating");

    updateLocationGroups.mutate(
      {
        revision_id,
        payload: { revision_id, groups },
      },
      {
        onError: (error) => {
          console.log("handleImportHorizonsLocationGroups error", error);
          setBusyStatus("error");
        },
        onSuccess: async () => {
          setBusyMessage(
            "Successfully imported the horizons for the location groups",
          );
          setBusyStatus("success");
          await sleep(2000);
          setBusyStatus("idle");
          setOpen(false);
        },
      },
    );
  }, [
    csvData,
    horizons,
    locationGroups,
    revision_id,
    setOpen,
    updateLocationGroups,
  ]);

  const stepActions = useCallback(
    ({
      index,
      enablePrev = false,
      enableNext = false,
      isLastStep = false,
    }: {
      index: number;
      enablePrev?: boolean;
      enableNext?: boolean;
      isLastStep?: boolean;
    }) => (
      <Box sx={{ mb: 0 }}>
        <Button
          variant="text"
          disabled={!enableNext}
          onClick={isLastStep ? handleImportHorizonsLocationGroups : handleNext}
          sx={{ mt: 1, mr: 1 }}
        >
          {isLastStep ? "Import horizons data" : "Continue"}
        </Button>
        {index > 0 && (
          <Button
            variant="text"
            disabled={!enablePrev}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
        )}
      </Box>
    ),
    [handleImportHorizonsLocationGroups],
  );

  const isStepImport = useMemo(() => csvData === null, [csvData]);

  const isStepHorizonInvalid = useMemo(() => {
    if (isStepImport) return true;
    return horizonStats.hasMissingHorizons;
  }, [horizonStats.hasMissingHorizons, isStepImport]);

  const isStepHorizonMatchInvalid = useMemo(() => {
    if (isStepHorizonInvalid) return true;
    return matchedLocationGroups.matchedCount === 0;
  }, [isStepHorizonInvalid, matchedLocationGroups.matchedCount]);

  const isStepConfirmImportInvalid = useMemo(
    () => isStepHorizonMatchInvalid,
    [isStepHorizonMatchInvalid],
  );

  return (
    <NDialog
      dialogTitle="Import geophysical horizons from csv file"
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="xl"
      actions={actions}
    >
      <div className="max-w-wide w-[900px] mx-auto">
        {busyStatus !== "idle" ? (
          <div className="flex flex-col items-center justify-center w-full">
            <Box sx={{ m: 1, position: "relative" }}>
              <Fab
                aria-label="save"
                color={
                  busyStatus === "mutating"
                    ? "primary"
                    : busyStatus === "error"
                      ? "error"
                      : "success"
                }
              >
                {busyStatus === "success" ? (
                  <Check />
                ) : busyStatus === "error" ? (
                  <Error />
                ) : (
                  <FileDownload />
                )}
              </Fab>
              {busyStatus === "mutating" && (
                <CircularProgress
                  size={68}
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography
              variant="h6"
              color={
                busyStatus === "mutating"
                  ? "primary"
                  : busyStatus === "error"
                    ? "error"
                    : "success"
              }
            >
              {busyMessage}
            </Typography>
            {busyStatus === "error" && (
              <div className="mt-4">
                <NButton variant="text" onClick={() => handleReset()}>
                  Restart the import process
                </NButton>
              </div>
            )}
          </div>
        ) : (
          <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>
                  <Typography variant="h6">
                    Select csv file to import
                  </Typography>
                </StepLabel>
                <StepContent>
                  <input type="file" accept=".csv" onChange={handleFileInput} />
                  {stepActions({ index: 0, enableNext: csvData !== null })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel error={isStepHorizonInvalid}>
                  <Typography
                    variant="h6"
                    color={isStepImport ? "error" : "black"}
                  >
                    Create horizons
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4">
                    {!csvData || !horizonStats ? (
                      <NBaseAlert
                        severity="error"
                        title="No data imported"
                        description="No data imported"
                      />
                    ) : (
                      <div className="grid grid-cols-3 justify-start items-center gap-4 pt-2 pb-4">
                        <div className="col-span-2">
                          <NBaseAlert
                            severity="info"
                            title="Horizons"
                            description={
                              horizonStats.hasMissingHorizons ? (
                                <>
                                  <Typography variant="body1">
                                    <b>
                                      {horizonStats.foundHorizonNames.length}
                                    </b>{" "}
                                    horizons already exist in the revision.
                                  </Typography>
                                  <Typography variant="body1">
                                    <b>
                                      {horizonStats.notFoundHorizonNames.length}
                                    </b>{" "}
                                    horizons will be created.
                                  </Typography>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  All horizons already exist in the revision.
                                  The existing one will be used in the next
                                  step.
                                </Typography>
                              )
                            }
                          />
                        </div>
                        {horizonStats.hasMissingHorizons && (
                          <div className="grow">
                            <NButton variant="text" onClick={handleAddHorizons}>
                              Add horizons to the revision
                            </NButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {stepActions({
                    index: 1,
                    enablePrev: true,
                    enableNext: !isStepHorizonMatchInvalid,
                  })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel error={isStepHorizonMatchInvalid}>
                  <Typography
                    variant="h6"
                    color={isStepHorizonMatchInvalid ? "error" : "black"}
                  >
                    Match horizons and location groups
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4">
                    {!csvData ? (
                      <NBaseAlert
                        severity="error"
                        title="No data imported"
                        description="No data imported"
                      />
                    ) : isStepImport ? (
                      <div>
                        <div className="flex flex-row justify-start items-center space-x-2 pt-2 pb-4">
                          <NBaseAlert
                            severity="error"
                            title="Could not match all locations from Excel to FM-SIITE"
                            description={
                              <>
                                <Typography variant="body1">
                                  <b></b> locations matched.
                                </Typography>
                                <Typography variant="body1">
                                  <b></b> locations could not be matched.
                                </Typography>
                              </>
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-3 justify-start items-center gap-4 pt-2 pb-4">
                        <div className="col-span-2">
                          <NBaseAlert
                            severity="info"
                            title="Location groups matched"
                            description={
                              <Typography variant="body1">
                                <b>{matchedLocationGroups.matchedCount}</b> of{" "}
                                <b>{locationGroups.length}</b> location groups
                                matched.
                              </Typography>
                            }
                          />
                        </div>
                        <div className="grow">
                          <FormControl fullWidth>
                            <InputLabel id="matching-method-label">
                              Matching method
                            </InputLabel>
                            <Select
                              labelId="matching-method-label"
                              id="matching-method"
                              value={matchingMethod}
                              label="Matching method"
                              onChange={handleChange}
                              disabled
                            >
                              <MenuItem value="name">by name</MenuItem>
                              <MenuItem value="coordinates">
                                by coordinates
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-span-3">
                          <NBaseAlert
                            severity="info"
                            title="Note"
                            description={
                              <Typography variant="body1">
                                For now only matching by name is available
                              </Typography>
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {stepActions({
                    index: 1,
                    enablePrev: true,
                    enableNext: !isStepHorizonMatchInvalid,
                  })}
                </StepContent>
              </Step>
              <Step>
                <StepLabel error={isStepConfirmImportInvalid}>
                  <Typography
                    variant="h6"
                    color={isStepConfirmImportInvalid ? "error" : "black"}
                  >
                    Import the horizons for the location groups
                  </Typography>
                </StepLabel>
                <StepContent>
                  <div className="flex flex-col space-y-4"></div>

                  {stepActions({
                    index: 3,
                    enablePrev: true,
                    enableNext: true,
                    isLastStep: true,
                  })}
                </StepContent>
              </Step>
            </Stepper>
          </Box>
        )}
      </div>
    </NDialog>
  );
};
