import { useCallback, useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { NBaseAlert, NButton, NDialog } from "@ngi/react-component";
import { deleteRevisionHorizonMutationQuery } from "src/queries/mutations";
import { sleep } from "src/utils/sleep";

type Props = {
  open: boolean;
  setOpen: (b: boolean) => void;
  revision_id: string;
  horizons: string[];
  onDeleteStart: () => void;
  onDeleteEnd: () => void;
};

export const DeleteHorizonDialog = ({
  open,
  setOpen,
  revision_id,
  horizons,
  onDeleteStart,
  onDeleteEnd,
}: Props) => {
  const deleteHorizonMutation = useMutation(
    deleteRevisionHorizonMutationQuery(revision_id),
  );

  const handleDeleteSoilUnit = useCallback(async () => {
    onDeleteStart();
    horizons.forEach((horizon_id) => {
      deleteHorizonMutation.mutate({ horizon_id });
    });
    setOpen(false);
    await sleep(2000);
    onDeleteEnd();
  }, [deleteHorizonMutation, horizons, onDeleteEnd, onDeleteStart, setOpen]);

  const actions = useMemo(
    () => (
      <>
        <NButton
          onClick={handleDeleteSoilUnit}
          variant="destructive"
          startIcon={<Delete />}
        >
          Delete horizons
        </NButton>
        <NButton onClick={() => setOpen(false)} variant="text">
          Cancel
        </NButton>
      </>
    ),
    [handleDeleteSoilUnit, setOpen],
  );

  return (
    <NDialog
      dialogTitle="Delete selected horizons"
      onClose={() => setOpen(false)}
      open={open}
      actions={actions}
    >
      <Box>
        <NBaseAlert
          title="Are you sure you want to delete those horizons?"
          description="The operation cannot be undone."
          severity="warning"
        />
      </Box>
    </NDialog>
  );
};
