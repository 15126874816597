import { useMemo } from "react";
import { Box } from "@mui/material";

import { rectangleLayer } from "src/components/common/plots/elements/rectangle";
import { vSpan } from "src/components/common/plots/elements/span";
import { PlotAxis, RenderLayerProp } from "src/components/common/plots/types";
import { XYPlot } from "src/components/common/plots/XYPlot";
import { HORIZON_COLOR } from "src/definitions/constants";
import { SOIL_UNIT_COLOR_OPACITY_OVERVIEW } from "src/definitions/plotConstants";
import {
  setLayerForPLot,
  SetLayerForPLotReturn,
} from "src/pages/revisionSoilLayering/soil-layering-utils";
import { SoilUnitSchemaType } from "src/schemas/unitSoilLayerSchema";
import { Horizon } from "src/types/horizon";
import { LocationGroup, LocationGroupHorizon } from "src/types/locationGroup";
import { TableCustomCell } from "src/types/table";
import { hexToRGBA } from "src/utils/color";

const margins = { top: 1, left: 8, bottom: 30, right: 10 };
type Props = {
  id: string;
  layers: SetLayerForPLotReturn;
  horizons: LocationGroupHorizon[];
  max_depth_all_group: number;
  showHorizons: boolean;
  horizonMapping: Record<string, Horizon>;
};

const LayeringPlot = ({
  id,
  layers,
  horizons,
  max_depth_all_group,
  showHorizons,
  horizonMapping,
}: Props) => {
  const localLayers = useMemo(() => layers, [layers]);
  const yScaleDataRange = [0, max_depth_all_group] as [number, number];

  const xAxisOptions: PlotAxis = {
    dataRange: yScaleDataRange as [number, number],
    label: "depth",
    position: "bottom",
    maxZoomLevel: 5,
  };

  const yAxisOptions: PlotAxis = {
    dataRange: [0, 1] as [number, number],
    reverse: true,
    label: "",
    position: "left",
    maxZoomLevel: 5,
  };

  const renderLayers = useMemo(() => {
    const items = localLayers.map((l, i) => {
      const topDepth = l.from_depth;
      const bottomDepth = l.to_depth;
      const sanitizedDepth = l.to_depth.toString().replace(/\./g, "-");
      const uniqueId = `layer-rectangle-${sanitizedDepth}-${i}`;
      return rectangleLayer({
        id: uniqueId,
        data: {
          y1: 0,
          y2: 1,
          x1: topDepth,
          x2: bottomDepth,
        },
        options: {
          style: {
            fill: hexToRGBA(l.color, SOIL_UNIT_COLOR_OPACITY_OVERVIEW),
            stroke: hexToRGBA(l.color, 0),
          },
        },
      });
    });

    const horizonItems: RenderLayerProp[] = [];

    if (showHorizons) {
      if (horizons.length > 0) {
        horizons.forEach((horizon, i) => {
          const depth = horizon.depth;
          if (depth) {
            const color =
              horizonMapping[horizon.horizon_id]?.color || HORIZON_COLOR;
            const sanitizedDepth = depth.toString().replace(/\./g, "-");
            const uniqueId = `horizon-${sanitizedDepth}-${i}`;
            horizonItems.push(
              vSpan({
                id: uniqueId,
                x: depth,
                options: { style: { stroke: color, strokeWidth: 2 } },
              }),
            );
          }
        });
      }
    }

    return [...items, ...horizonItems];
  }, [horizonMapping, horizons, localLayers, showHorizons]);

  return (
    <div className="h-12">
      <XYPlot
        plotId={id}
        margins={margins}
        xAxisOptions={xAxisOptions}
        yAxisOptions={yAxisOptions}
        renderLayers={renderLayers}
        useGrid={false}
        useXAxis={true}
        useYAxis={false}
      />
    </div>
  );
};

type LayerBarChartCellProps = {
  soilUnits: SoilUnitSchemaType[];
  horizonMapping: Record<string, Horizon>;
  max_depth_all_group: number;
  showHorizons: boolean;
};

export const LayerBarChartCellFactory = ({
  soilUnits,
  horizonMapping,
  max_depth_all_group,
  showHorizons,
}: LayerBarChartCellProps) => {
  const Component = ({
    getValue,
    row,
  }: TableCustomCell<LocationGroup, LocationGroup["layers"]>) => {
    {
      const layers = getValue() || [];
      const { location_group_id, max_depth, horizons } = row.original;
      const layersForPlot = setLayerForPLot({ layers, max_depth, soilUnits });

      return (
        <Box className="px-2">
          {layers.length > 0 ? (
            <LayeringPlot
              id={location_group_id}
              layers={layersForPlot}
              max_depth_all_group={
                max_depth_all_group > 0 ? max_depth_all_group : max_depth
              }
              showHorizons={showHorizons}
              horizonMapping={horizonMapping}
              horizons={horizons || []}
            />
          ) : (
            <div>No layers</div>
          )}
        </Box>
      );
    }
  };

  Component.displayName = "LayerBarChartCell";
  return Component;
};
