import { Box } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";

import { Datatable } from "src/components/common/datatable/Datatable";
import { rowSelectionColumn } from "src/components/common/datatable/features/rowSelection";
import { useRowSelection } from "src/components/common/datatable/hooks/useRowSelection";
import { Project } from "src/types/api";

type Props = {
  projects: Project[];
  selectedProjectsToAdd: string[];
  setSelectedProjectsToAdd: (project_ids: string[]) => void;
};

const columnHelper = createColumnHelper<Project>();

export const AddProjectTable = ({
  projects,
  selectedProjectsToAdd,
  setSelectedProjectsToAdd,
}: Props) => {
  const columns = [
    rowSelectionColumn(),
    columnHelper.accessor("project_external_id", {
      cell: (row) => row.getValue(),
      header: "Project ID",
      size: 120,
      sortUndefined: "last",
      sortDescFirst: false,
      filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
    }),
    columnHelper.accessor("name", {
      cell: (row) => row.getValue(),
      header: "Project name",
      size: 500,
      sortUndefined: "last",
      sortDescFirst: false,
      filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
    }),
    columnHelper.accessor("description", {
      cell: (row) => row.getValue(),
      header: "Project description",
      sortUndefined: "last",
      sortDescFirst: false,
    }),
  ];

  const { rowSelection, handleOnRowSelectionChange } = useRowSelection<Project>(
    {
      data: projects,
      rowKey: "project_id",
      initialRowSelection: selectedProjectsToAdd,
      onRowSelectionChange: setSelectedProjectsToAdd,
    },
  );

  return (
    <Box>
      <Datatable
        data={projects}
        columns={columns}
        usePagination={projects.length > 10}
        useRowSelection
        rowSelection={rowSelection}
        setRowSelection={handleOnRowSelectionChange}
        getRowId={(row: Project) => row.project_id}
        defaultSorting={[{ id: "name", desc: false }]}
      />
    </Box>
  );
};
