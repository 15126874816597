// Outside of dev, frontend and backend is served from same url, so we can use relative paths
export const API_BASE_URL = import.meta.env.DEV ? "http://localhost:5858" : "";

export const FM_PROD_URL = "https://app.fieldmanager.io";
export const FM_TEST_URL = "https://app.test.fieldmanager.io";

export const MAP_MARKER_PRIMARY = "#0073D1";
export const MAP_MARKER_PENDING = "#999999";
export const MAP_MARKER_ERROR = "#b00020";

export const DEFAULT_SOIL_UNIT_COLOR = "#9c9c9c";

export const HORIZON_COLOR = "#ea580c";
export const HORIZON_DEPTH_OFFSET = -0.15;

export const LAYER_CIRCLE_RADIUS = 8;
export const SUBLAYER_CIRCLE_RADIUS = 8;
export const LAYER_LINE_COLOR = "#000";
export const SUBLAYER_LINE_COLOR = "#666";
export const SOIL_UNIT_NAME_COLOR = "#444";
