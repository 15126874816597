import { queryOptions } from "@tanstack/react-query";

import { getAuthSession } from "src/api/auth";
import { getConfig } from "src/api/config";
import { getCPT, getProcessedCPTs, GetProcessedCPTsProps } from "src/api/cpt";
import { getRevisionHorizons } from "src/api/horizon";
import { getRevisionLocations } from "src/api/location";
import { getRevisionLocationGroups } from "src/api/locationGroup";
import {
  getProject,
  getProjectExternalLocations,
  getProjectPhases,
  getProjects,
} from "src/api/project";
import {
  exportGlobalUnitLayerFileRevision,
  exportParentFileRevision,
  getProjectPhaseRevision,
  getProjectPhaseRevisions,
} from "src/api/projectPhaseRevision";
import { getRevisionSoilUnits } from "src/api/soilUnit";
import { min2milliseconds } from "src/utils/math";
import { keys } from "./keys";

export const getAuthSessionQuery = queryOptions({
  queryKey: keys.auth,
  queryFn: getAuthSession,
  refetchInterval: 1000 * 30,
  refetchIntervalInBackground: true,
});

export const getAppConfig = queryOptions({
  queryKey: keys.config,
  queryFn: getConfig,
});

export const getAllProjectsQuery = queryOptions({
  queryKey: keys.allProjects,
  queryFn: getProjects,
});

export const getProjectQuery = (project_id: string) =>
  queryOptions({
    queryKey: keys.project(project_id),
    queryFn: () => getProject({ project_id }),
    enabled: project_id !== "",
  });

export const getProjectPhasesQuery = (project_id: string) =>
  queryOptions({
    queryKey: keys.projectPhases(project_id),
    queryFn: () => getProjectPhases({ project_id }),
    enabled: project_id !== "",
  });

export const getProjectFieldManagerLocationsQuery = (project_id: string) =>
  queryOptions({
    queryKey: keys.projectFieldManagerLocations(project_id),
    queryFn: () => getProjectExternalLocations({ project_id }),
  });

export const getRevisionLocationsQuery = (
  project_id: string,
  phase_id: string,
  revision_id: string,
  hasLocationCPTMissing: boolean = false,
) =>
  queryOptions({
    queryKey: keys.projectPhaseRevisionLocations(
      project_id,
      phase_id,
      revision_id,
    ),
    queryFn: () => getRevisionLocations({ revision_id }),
    staleTime: hasLocationCPTMissing ? 1000 : min2milliseconds(10),
    gcTime: min2milliseconds(15),
  });

export const getRevisionQuery = (
  project_id: string,
  phase_id: string,
  revision_id: string,
) =>
  queryOptions({
    queryKey: keys.projectPhaseRevision(project_id, phase_id, revision_id),
    queryFn: () => getProjectPhaseRevision({ revision_id }),
    enabled: revision_id !== null || revision_id !== "",
  });

export const getProjectPhaseRevisionsQuery = (
  project_id: string,
  phase_id: string,
) =>
  queryOptions({
    queryKey: keys.projectPhaseRevisions(project_id, phase_id),
    queryFn: () => getProjectPhaseRevisions({ phase_id }),
  });

export const getCPTQuery = (project_id: string, siite_location_id: string) =>
  queryOptions({
    queryKey: keys.locationCptRawFile(project_id, siite_location_id),
    queryFn: () => getCPT({ siite_location_id }),
    enabled: siite_location_id !== null && siite_location_id !== "",
    staleTime: min2milliseconds(10),
    gcTime: min2milliseconds(15),
  });

export const getRevisionLocationGroupQuery = (revision_id: string) =>
  queryOptions({
    queryKey: keys.revisionLocationGroups(revision_id),
    queryFn: () => getRevisionLocationGroups({ revision_id }),
  });

export const getRevisionSoilUnitQuery = (revision_id: string) =>
  queryOptions({
    queryKey: keys.revisionSoilUnits(revision_id),
    queryFn: () => getRevisionSoilUnits({ revision_id }),
  });

export const getRevisionHorizonQuery = (revision_id: string) =>
  queryOptions({
    queryKey: keys.revisionHorizons(revision_id),
    queryFn: () => getRevisionHorizons({ revision_id }),
  });

export const getRevisionLocationGroupProcessedCPTsQuery = (
  revision_id: string,
  location_group_id: string,
  payload: GetProcessedCPTsProps["payload"],
) =>
  queryOptions({
    queryKey: keys.revisionLocationGroupProcessedCPTs(
      revision_id,
      location_group_id,
    ),
    queryFn: () => getProcessedCPTs({ location_group_id, payload }),
    staleTime: min2milliseconds(10),
    gcTime: min2milliseconds(15),
  });

export const getRevisionExcelParentFileQuery = (revision_id: string) =>
  queryOptions({
    queryKey: keys.revisionParentFileExcelExport(revision_id),
    queryFn: () => exportParentFileRevision({ revision_id }),
    // staleTime: min2milliseconds(10),
    // gcTime: min2milliseconds(15),
    enabled: false,
  });

export const getRevisionExcelGlobalUnitLayerFileQuery = (revision_id: string) =>
  queryOptions({
    queryKey: keys.revisionGlobalUnitLayerFileExcelExport(revision_id),
    queryFn: () => exportGlobalUnitLayerFileRevision({ revision_id }),
    // staleTime: min2milliseconds(10),
    // gcTime: min2milliseconds(15),
    enabled: false,
  });
