export function hexToRGBA(hex: string, alpha: number): string {
  // Remove the hash symbol if it exists
  const cleanHex = hex.replace("#", "");

  // Validate hex format
  if (!/^[0-9A-Fa-f]{6}$/i.test(cleanHex)) {
    throw new Error("Invalid hex color format");
  }

  // Parse the hex color to its RGB components
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  // Return the RGBA string with the alpha value
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function rgbaToHex(rgba: string): string {
  // Extract RGBA components using a regular expression
  const regex = /rgba?\((\d+), (\d+), (\d+)(?:, (\d*\.?\d+))?\)/;
  const match = rgba.match(regex);

  if (!match) {
    throw new Error("Invalid RGBA string");
  }

  // Get the red, green, blue, and alpha values
  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);
  const alpha = match[4] ? parseFloat(match[4]) : 1; // Default to 1 if alpha is not provided

  // Convert RGB components to hex format
  const hex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

  // If alpha exists and is less than 1, include it in the result
  if (alpha < 1) {
    // Custom alpha conversion
    let alphaHex = Math.round(alpha * 255)
      .toString(16)
      .padStart(2, "0");

    // Special handling for 0.5 alpha to output 00 in hex
    if (alpha === 0.5) {
      alphaHex = "00"; // Force 50% alpha to '00'
    }

    return `${hex}${alphaHex}`;
  }

  return hex;
}

/**
 * Converts a hex color to RGB components.
 * @param hex - The hex color string (e.g., "#RRGGBB" or "#RGB").
 * @returns An object with r, g, and b values (0-255).
 */
function hexToRgb(hex: string): { r: number; g: number; b: number } {
  let normalizedHex = hex.replace(/^#/, "");
  if (normalizedHex.length === 3) {
    normalizedHex = normalizedHex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  const bigint = parseInt(normalizedHex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

/**
 * Calculates the relative luminance of a color.
 * @param rgb - RGB components {r, g, b}.
 * @returns The relative luminance (0 - 1).
 */
function getLuminance({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): number {
  const channelToLuminance = (channel: number) => {
    const c = channel / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  };

  const rLum = channelToLuminance(r);
  const gLum = channelToLuminance(g);
  const bLum = channelToLuminance(b);

  return 0.2126 * rLum + 0.7152 * gLum + 0.0722 * bLum;
}

/**
 * Calculates the contrast ratio between two colors.
 * @param color1 - RGB of first color.
 * @param color2 - RGB of second color.
 * @returns The contrast ratio (>= 1).
 */
function getContrastRatio(
  color1: { r: number; g: number; b: number },
  color2: { r: number; g: number; b: number },
): number {
  const lum1 = getLuminance(color1);
  const lum2 = getLuminance(color2);
  const brighter = Math.max(lum1, lum2);
  const darker = Math.min(lum1, lum2);

  if (lum1 === 0 && lum2 > 0.5) return 0;

  return (brighter + 0.05) / (darker + 0.05);
}

/**
 * Determines the ideal text color (black or white) based on background luminance.
 * @param backgroundColor - The background color in hex format.
 * @returns "#FFFFFF" for white text or "#000000" for black text.
 */
function getIdealTextColor(backgroundColor: string): string {
  const bgRgb = hexToRgb(backgroundColor);
  const bgLuminance = getLuminance(bgRgb);

  // If background is "bright", return black text; otherwise, return white.
  return bgLuminance < 0.5 ? "#000000" : "#FFFFFF";
}

/**
 * Validates the text color contrast and adjusts if necessary.
 * @param backgroundColor - The background color in hex format.
 * @param textColor - The text color in hex format.
 * @param minContrast - The minimum required contrast ratio (default: 4.5 for WCAG AA).
 * @returns The original text color if contrast is sufficient, otherwise adjusted (black/white).
 */
export function validateTextColor(
  backgroundColor: string,
  textColor: string,
  minContrast: number = 3,
): string {
  const bgRgb = hexToRgb(backgroundColor);
  const textRgb = hexToRgb(textColor);

  const contrastRatio = getContrastRatio(bgRgb, textRgb);

  // Return original text color if the contrast is sufficient
  if (contrastRatio >= minContrast) {
    return textColor;
  }

  // Otherwise, return ideal black or white text based on luminance
  return getIdealTextColor(backgroundColor);
}
